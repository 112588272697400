import firebase from "firebase/app";
import 'firebase/database';
import 'firebase/analytics';

var firebaseConfig = {
  apiKey: "AIzaSyAmV5QihsTPg3vpwZWkP5yUQm2XgdFPgzk",
  authDomain: "ideaqueue-e809d.firebaseapp.com",
  databaseURL: "https://ideaqueue-e809d-default-rtdb.firebaseio.com",
  projectId: "ideaqueue-e809d",
  storageBucket: "ideaqueue-e809d.appspot.com",
  messagingSenderId: "974592303229",
  appId: "1:974592303229:web:a77b9d9f1369134fa91114",
  measurementId: "G-0WE22H7CEW"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const db = firebase.database();

export default db;