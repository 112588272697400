import "./App.css";
import { useState, useEffect } from "react";
import { getList, deleteIdea, getRecent, setIdeas, addIdea } from "./db";
import styled from "styled-components";

const Load = styled.button`
  background-color: #0269a4;
  width: 120px;
  height: 40px;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  :hover {
    opacity: 0.9;
  }
`;

const Undo = styled.button`
  background-color: #0269a4;
  width: 60px;
  height: 40px;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  :hover {
    opacity: 0.9;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 16px;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.div`
  padding: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: left;
  margin: auto;
  :hover {
    background-color: #f9f9f9;
  }
`;

const ListWrapper = styled.div`
  text-align: center;
  margin: auto;
  min-width: 20em;
  max-width: 40em;
  padding: 0 20px 0 20px;
`;

function App() {
  const [list, setList] = useState([]);
  const [refreshed, setRefreshed] = useState(false);
  const [stack, setStack] = useState([]);

  function updateList() {
    getList().then((snapshot) => setList(snapshot.val()));
  }

  function loadRecent() {
    getRecent()
      .then((snapshot) => {
        setList(snapshot.val());
        return snapshot;
      })
      .then((snapshot) => setIdeas(snapshot.val()));
  }

  function removeIdea(val) {
    deleteIdea(val[0]).then(() => {
      updateList();
    });
    setStack((stack) => [...stack, val[1]]);
  }

  function popFromStack() {
    if (stack.length === 0) {
      console.log("This shouldn't happen");
      return;
    }
    console.log("Hello");
    var lastDeleted = stack[stack.length - 1];
    setStack((stack) => stack.slice(0, -1));
    addIdea(lastDeleted).then(updateList());
  }

  useEffect(() => {
    console.log("running");
    updateList();
  }, []);

  return (
    <div className="App">
      <br />
      {list && list !== undefined ? (
        <>
          {!refreshed ? (
            <>
              <Load
                onClick={() => {
                  updateList();
                  setRefreshed(true);
                }}
              >
                Refresh
              </Load>
              <br />
              <br />
            </>
          ) : null}
          <ListWrapper>
            {Object.entries(list).map((val, index) => {
              return (
                <ListItem key={val[0]} onClick={() => removeIdea(val)}>
                  {val[1]}
                </ListItem>
              );
            })}
          </ListWrapper>
          {stack.length !== 0 ? (
            <Undo
              onClick={() => {
                popFromStack();
              }}
            >
              <FlexDiv>
                <Logo src="./undo.png" />
              </FlexDiv>
            </Undo>
          ) : null}
        </>
      ) : (
        <Load
          onClick={() => {
            loadRecent();
          }}
        >
          Load Recent
        </Load>
      )}
    </div>
  );
}

export default App;
