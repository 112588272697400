import "../App.css";
import { useState, useEffect } from "react";
import { getAllList } from "../db";
import styled from "styled-components";

const ListItem = styled.div`
  padding: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: left;
  margin: auto;
  :hover {
    background-color: #f9f9f9;
  }
`;

const ListWrapper = styled.div`
  text-align: center;
  margin: auto;
  min-width: 20em;
  max-width: 40em;
  padding: 0 20px 0 20px;
`;

function All() {
  const [list, setList] = useState([]);

  function updateList() {
    getAllList().then((snapshot) => setList(snapshot.val()));
  }

  useEffect(() => {
    updateList();
  }, []);

  return (
    <div className="App">
      <br />
      {list && list !== undefined ? (
        <>
          <ListWrapper>
            {Object.entries(list).map((val, index) => {
              return <ListItem key={val[0]}>{index+1}) {val[1]}</ListItem>;
            })}
          </ListWrapper>
        </>
      ) : null}
    </div>
  );
}

export default All;
